@import "../../sass/variables.scss";

.statistics {
  &__day {
    width: 20px;
    height: 20px;
    background: darken($background, 10%);
    border: 1px solid lighten($background, 15%);
    border-radius: 3px;
    box-sizing: border-box;
    margin: 6px auto;
    cursor: pointer;
    transition: all $animation-time-quick;
    position: relative;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;

    &:hover {
      background: lighten($background, 15%);
      border: 1px solid lighten($background, 40%);
    }

    &--today {
      background: rgb(0, 144, 170);
    }

    &--drinking {
      &:before {
        content: "\f00d";
        position: absolute;
        left: 4px;
        top: -3px;
        color: #d22929;
      }
    }

    &--not-visible {
      visibility: hidden;
    }

    &--legend {
      display: inline-block;
      margin: 0 5px;
    }
  }

  &__day-sub {
    font-size: 13px;
    line-height: 22px;
    margin: 4px auto;
  }

  &__legend {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    position: relative;
  }

  &__month {
    position: absolute;
    top: -14px;
    left: 14px;
    font-size: 13px;
  }
}
