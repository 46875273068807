$background: #111325;
$primary: #808AB8;
$text: #808AB8;
$link: #3381FF;


$margin-default: 15px;


$animation-time-quick: 0.3s;
$animation-time-long: 0.8s;
